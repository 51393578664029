<template>
  <apexchart
    ref="chart"
    type="line"
    :options="options"
    :series="series"
    :width="width"
    :height="height"
  ></apexchart>
</template>
<script>
import API_HISOTRY_GRAPH from "../API/reading/historyGraph";
import API_SCOL_HISOTRY_GRAPH from "../API/reading/scolHistoryGraph";
export default {
  components: {},
  props: {
    xrayType: {
      default: "",
    },
    xrayNo: {
      default: 0,
    },
    width: {
      default: "100%",
    },
    height: {
      default: "100%",
    },
    isScoliosis: {
      default: false,
    },
    isReport: {
      default: true,
    },
    graphType: {
      default: "maxAngle",
    },
  },
  data() {
    return {
      loading: false,
      data1List: [],
      data2List: [],
      timeList: [],
      options: {
        chart: {
          animations: {
            easing: "easeinout",
            speed: 500,
          },
          foreColor: "#fff", // 텍스트 색상을 흰색으로 설정
          id: "vuechart-example",
          type: "line",
          background: { opacity: 0 },
          zoom: { enabled: false },
          toolbar: { tools: { download: false } },
        },
        theme: { mode: this.isReport ? "light" : "dark" }, // isReport에 따라 테마 설정
        xaxis: {
          tooltip: { enabled: false },
          labels: {
            style: {
              fontSize: "9.5px",
              colors: this.isReport ? "#000" : "#fff",
            }, // 텍스트 색상 조건부 설정
          },
          categories: this.timeList,
        },
        yaxis: {
          labels: {
            style: { colors: this.isReport ? "#000" : "#fff" }, // 텍스트 색상 조건부 설정
          },
        },
        colors: ["#eb4d4b", "#30336b"],
      },
      series: [],
    };
  },
  watch: {},
  async mounted() {
    //console.log('mounted: calling getHistoryGraph');
    await this.getHistoryGraph();
    //console.log('mounted: getHistoryGraph completed');
  },
  destroyed() {},
  methods: {
    async getHistoryGraph() {
      if (this.loading) return;
      this.loading = true;
      let res = await API_HISOTRY_GRAPH.request(
        this.xrayType,
        this.xrayNo,
        this.graphType
      );
      if (res.isSuccess) {
        const dataList = res.list ?? [];
        //console.log("Received dataList:", dataList); // 디버깅을 위한 콘솔 출력
        this.initDataList(dataList);
        this.setGraph(dataList);
        this.loading = false;
      } else {
        this.showPopup(res.errorMsg, res.status);
        this.loading = false;
      }
      this.$emit("loading", this.loading);
    },
    initDataList(dataList) {
      if (!Array.isArray(dataList)) {
        console.error("dataList is not an array");
        return;
      }
      this.data1List = [];
      this.data2List = [];
      this.timeList = [];
    },
    setGraph(dataList) {
      dataList.forEach((element) => {
        //console.log("Data element:", element); // 디버깅을 위한 콘솔 출력
        if (element && typeof element.data1 === "number" && element.pictureDt) {
          // 필터링 조건에서 element.no 제거
          this.data1List.push(element.data1);
          const date = new Date(element.pictureDt);
          this.timeList.push(this.setDate(date));
        }
      });
      this.series = [
        {
          name: this.graphType === "risser" ? "Risser Grade" : "최대각도",
          data: this.data1List,
        },
      ];
      this.setOptions();
    },
    setDate(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    setOptions() {
      // yAxisFormatter 함수 수정
      let yAxisFormatter = (val) => {
        return this.graphType === "risser"
          ? val.toFixed(0)
          : val.toFixed(0) + " °";
      };

      // 툴팁의 formatter 함수 수정
      let tooltipFormatter = (value) => {
        return this.graphType === "risser"
          ? value.toFixed(1)
          : value.toFixed(1) + " °";
      };

      if (this.graphType !== "risser" && this.data1List.length > 0) {
        // Min and Max values with 1 degree padding
        const minValue = Math.min(...this.data1List);
        const maxValue = Math.max(...this.data1List);

        var yaxisOptions = {
          min: minValue - 1, // Min 값보다 1도 낮게 설정
          max: maxValue + 1, // Max 값보다 1도 높게 설정
          labels: {
            formatter: yAxisFormatter, // Y축 라벨 포맷터
          },
          forceNiceScale: true,
        };
      } else {
        var yaxisOptions = {
          min: 0,
          max: 5,
          labels: {
            formatter: yAxisFormatter, // Risser의 경우도 동일하게 설정
          },
          forceNiceScale: true,
        };
      }

      this.options = {
        ...this.options,
        yaxis: yaxisOptions,
        legend: { showForSingleSeries: true },
        xaxis: {
          categories: this.timeList, // X축은 날짜로 표시
          type: "datetime",
          tooltip: { enabled: false },
          labels: {
            format: "yyyy-MM-dd", // 날짜 포맷 설정
            style: {
              fontSize: "9.5px",
              colors: this.isReport ? "#000" : "#fff",
            },
            datetimeUTC: false,
          },
        },
        tooltip: {
          y: {
            formatter: tooltipFormatter, // 수정된 툴팁 포맷터 적용
          },
        },
      };
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "@/sass/app.scss";
</style>
